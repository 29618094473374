import React from 'react'
import PageSEO from '../components/seo'
import PageMainContent from '../components/page-main-content'
import MainForSection from '../components/page-sections/main-form-section/MainFormSection'
import { IStrapiPage, IStrapiPageLayout } from '../types/strapi'
import { graphql, PageProps } from 'gatsby'


export interface IPageDataResponse {
  strapiPage: IStrapiPage
}

export interface IPageContext {
  id: string
  layout: IStrapiPageLayout
}

const Page: React.FC<PageProps<IPageDataResponse, IPageContext>> = ({ data }) => {
  const { mainForm, sections = [] } = data.strapiPage

  return (
    <>
      <PageMainContent sections={sections} />
      <MainForSection data={mainForm} />
    </>
  )
}

export default Page

export const Head: React.FC<PageProps<IPageDataResponse>> = ({ data, location }) => {
  const { seo, preloadImage } = data.strapiPage

  return (
    <PageSEO
      title={seo?.title}
      description={seo?.description}
      pathname={location.pathname}
      preloadImage={preloadImage}
    />
  )
}

export const query = graphql`
  query($id: String!) {
    strapiPage(id: {eq: $id}) {
      seo {
        ...StrapiSEO
      }
      mainForm {
        ...StrapiMainFormSection
      }
      preloadImage {
        ...StrapiMedia
      }
      sections {
        ... on STRAPI__COMPONENT_SECTIONS_DEFAULT_SECTION {
          ...StrapiDefaultSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_QUOTE_SECTION {
          ...StrapiQuoteSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CASES_SECTION {
          ...StrapiCasesSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_LIST_SECTION {
          ...StrapiListSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_ICON_LIST_SECTION {
          ...StrapiIconListSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CAROUSEL_SECTION {
          ...StrapImagesCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_REVIEWS_CAROUSEL_SECTION {
          ...StrapReviewsCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_LICENSES_CAROUSEL_SECTION {
          ...StrapiLicensesCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CARDS_SECTION {
          ...StrapiBasicCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEXT_CARDS_SECTION {
          ...StrapiTextCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_IMAGE_CARDS_SECTION {
          ...StrapiImageCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_LICENSE_CARDS_SECTION {
          ...StrapiLicenseCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_REVIEW_CARDS_SECTION {
          ...StrapiReviewCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TABS_LAYOUT {
          ...StrapiTabsLayout
        }
        ... on STRAPI__COMPONENT_SECTIONS_GRID_LAYOUT {
          ...StrapiGridLayout
        }
        ... on STRAPI__COMPONENT_SECTIONS_IMAGE_SECTION {
          ...StrapiImageSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CASES_CAROUSEL_SECTION {
          ...StrapCasesCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_BUTTONS_SECTION {
          ...StrapiButtonsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_CASES_SELECTION_SECTION {
          ...StrapiCasesSelectionSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_WYSIWYG_SECTION {
          ...StrapiWysiwygSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_BLOG_SECTION {
          ...StrapiBlogSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_STEPS_SECTION {
          ...StrapiStepsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_FAQ_SECTION {
          ...StrapiFaqSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_SOCIALS_SECTION {
          ...StrapiSocialsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_BREAD_CRUMBS_SECTION {
          ...StrapiBreadCrumbsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TABS_LIST_SECTION {
          ...StrapiTabsListSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TEXT_CARDS_CAROUSEL_SECTION {
          ...StrapiTextCardsCarouselSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_ACTION_CARDS_SECTION {
          ...StrapiActionCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_TABLE_SECTION {
          ...StrapiTableSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_PRICE_TAGS_SECTION {
          ...StrapiPriceTagsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_PRICE_CARDS_SECTION {
          ...StrapiPriceCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_SIMPLE_CARDS_SECTION {
          ...StrapiSimpleCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_HOVERING_SECTION {
          ...StrapiHoveringSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_PRICE_LABEL_CARDS_SECTION {
          ...StrapiPriceLabelCardsSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_UNISENDER_FORM_SECTION {
          ...StrapiUnisenderFormSection
        }
        ... on STRAPI__COMPONENT_SECTIONS_COLLAGE_SECTION {
          ...StrapiCollageSection
        }
      }
    }
  }
`
